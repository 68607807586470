import React from 'react';

import Svg, { SvgProps } from 'components/icons/Svg';

const DocumentIcon: React.FC<SvgProps> = (props) => (
  <Svg height="1em" width="1em" viewBox="0 0 100 100" {...props}>
    <path
      fill="currentColor"
      d="M26.867 27.938h17.632v2.075H26.867zM26.867 33.903h36.866v2.075H26.867zM26.867 40.06h44.235v2.076H26.867zM26.867 46.026h44.235v2.075H26.867zM26.867 52.076h44.235v2.075H26.867zM26.867 58.04h44.235v2.076H26.867zM26.867 64.092h17.557v2.075H26.867zM54.725 72.823h16.377v2.075H54.725z"
    />
    <path
      fill="currentColor"
      d="M83.094 76.369l-4.285.003-.014-58.885a6.636 6.636 0 00-6.629-6.629H14.682c-3.687.001-6.688 3.001-6.688 6.689s3.001 6.69 6.689 6.69l4.288-.002.009 58.882c0 3.656 2.977 6.629 6.635 6.629h57.482c3.689-.002 6.689-3.001 6.689-6.688.001-3.687-3.001-6.689-6.692-6.689zM18.972 22.161l-4.288.002a4.618 4.618 0 01-4.614-4.615 4.618 4.618 0 014.614-4.614c2.444 0 4.288 1.989 4.288 4.627v4.6zm2.084 60.957l-.009-65.558c0-1.834-.642-3.44-1.7-4.627h52.819a4.56 4.56 0 014.555 4.554l.014 65.561c0 1.834.643 3.438 1.697 4.624H25.616a4.562 4.562 0 01-4.56-4.554zm62.038 4.554c-2.441 0-4.285-1.987-4.285-4.624v-4.601l4.285-.002a4.622 4.622 0 014.619 4.614 4.62 4.62 0 01-4.619 4.613z"
    />
    <path
      fill="currentColor"
      d="M90.307 32.436v-1.734c0-.859-.352-2.861-3.787-2.861-3.273 0-3.627 2.002-3.627 2.861l.027 30.448.111.062 3.514 5.948 3.688-5.83.096-.037-.018-26.782h.607v7.639h2.076v-9.714h-2.687zm-3.623-2.521c1.549 0 1.549.474 1.549.786v1.734h-3.262l-.002-1.734c0-.294 0-.786 1.715-.786zm-.723 32.179c.189.013.354.045.561.045.262 0 .527-.051.791-.077l-.707 1.12-.645-1.088zm2.293-2.274c-1.264.344-2.598.227-3.26.026l-.021-25.336h3.264l.017 25.31z"
    />
  </Svg>
);

export default DocumentIcon;
