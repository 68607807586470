import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import Box, { BoxProps } from 'components/Box';
import InnerWrap from 'components/layout/InnerWrap';
import OuterWrap from 'components/layout/OuterWrap';

const query = graphql`
  {
    cbaLogo: file(relativePath: { eq: "logo-cba.png" }) {
      childImageSharp {
        gatsbyImageData(height: 50, layout: FIXED)
      }
    }
    obaLogo: file(relativePath: { eq: "logo-oba.png" }) {
      childImageSharp {
        gatsbyImageData(height: 50, layout: FIXED)
      }
    }
    lsoLogo: file(relativePath: { eq: "logo-lso.png" }) {
      childImageSharp {
        gatsbyImageData(height: 50, layout: FIXED)
      }
    }
  }
`;

type Props = BoxProps;

const Certifications: React.FC<Props> = (props) => {
  const data = useStaticQuery(query);
  const { cbaLogo, obaLogo, lsoLogo } = data;
  return (
    <OuterWrap py={5} {...props}>
      <InnerWrap display="flex" alignItems="center" justifyContent="space-around" flexWrap="wrap">
        <Box px={2}>
          <GatsbyImage
            image={cbaLogo.childImageSharp.gatsbyImageData}
            alt="The Canadian Bar Association certification logo"
          />
        </Box>
        <Box px={2}>
          <GatsbyImage
            image={obaLogo.childImageSharp.gatsbyImageData}
            alt="Ontario Bar Association certification logo"
          />
        </Box>
        <Box px={2}>
          <GatsbyImage
            image={lsoLogo.childImageSharp.gatsbyImageData}
            alt="Law Society of Ontario certification logo"
          />
        </Box>
      </InnerWrap>
    </OuterWrap>
  );
};

export default Certifications;
