import React from 'react';

import Box, { BoxProps } from 'components/Box';

type Props = BoxProps;

const Card: React.FC<Props> = (props) => {
  return (
    <Box
      py={4}
      px={5}
      borderTop="2px solid"
      borderColor="gold"
      sx={{ boxShadow: 'card', ...props.sx }}
      {...props}
    />
  );
};

export default Card;
