import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, PortableTextBlocks } from 'types/schema';
import { contentMaxWidth } from 'components/theme/measurements';
import { formatPhoneNumber } from 'utils/helpers';
import Box, { BoxProps } from 'components/Box';
import Card from 'components/Card';
import Certifications from 'components/home/certifications';
import ChevronIcon from 'components/icons/ChevronIcon';
import ContactInfoSection from 'components/contact/ContactInfoSection';
import DocumentIcon from 'components/icons/DocumentIcon';
import Flex from 'components/Flex';
import Grid from 'components/Grid';
import H1 from 'components/H1';
import HandshakeIcon from 'components/icons/HandshakeIcon';
import InnerWrap from 'components/layout/InnerWrap';
import LatestResources from 'components/resources/LatestResources';
import Link from 'components/Link';
import LinkButton from 'components/LinkButton';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';
import OverlayBox from 'components/OverlayBox';
import PortableText from 'components/PortableText';
import dots from 'images/empty-dots-diag.svg';

const PracticeAreaCard: React.FC<
  BoxProps & { title: string; content: string; linkCopy: string; linkTo: string }
> = ({ title, content, linkCopy, linkTo }) => (
  <Box width={[1, 1 / 2, 1 / 3]} mt={7} px={3}>
    <Card display="flex" flexDirection="column" height="100%">
      <Box variant="header5Serif" textAlign="center">
        {title}
      </Box>
      <Box variant="body1" mt={4}>
        {content}
      </Box>
      <Link to={linkTo} display="flex" alignItems="center" justifyContent="center" pt={4} mt="auto">
        {linkCopy} <ChevronIcon dir="right" />
      </Link>
    </Card>
  </Box>
);

export const pageQuery = graphql`
  query HomePageQuery {
    sanityCompanyDetails(_id: { eq: "companyDetails" }) {
      phoneNumber
    }
    sanityHomePage(id: { eq: "-f5eb4dd0-87df-59ba-a4c9-a6342099c723" }) {
      meta {
        title
        description
      }
      pageTitle
      headerLinkCopy
      headerMotto
      practiceAreasBody
      businessLaw {
        title
        body
        ctaCopy
      }
      employeeLaw {
        title
        body
        ctaCopy
      }
      employerLaw {
        title
        body
        ctaCopy
      }
      wrongfulDismissal {
        title
        subtitle
        _rawBody
      }
      severance {
        title
        subtitle
        _rawBody
      }
      compensation {
        title
        body
        ctaCopy
      }
      approach {
        title
        body
        ctaCopy
      }
    }
  }
`;
type CTASection = {
  title: string;
  body: string;
  ctaCopy: string;
};
type PracticeAreaHighlight = {
  title: string;
  subtitle: string;
  _rawBody: PortableTextBlocks;
};
type QueryData = {
  sanityCompanyDetails: { phoneNumber: number };
  sanityHomePage: BaseSanityPage & {
    headerLinkCopy: string;
    headerMotto: string;
    practiceAreasBody: string;
    businessLaw: CTASection;
    employeeLaw: CTASection;
    employerLaw: CTASection;
    wrongfulDismissal: PracticeAreaHighlight;
    severance: PracticeAreaHighlight;
    compensation: CTASection;
    approach: CTASection;
  };
};

const HomePage: React.FC<{ data: QueryData }> = ({ data }) => {
  const { phoneNumber } = data.sanityCompanyDetails;
  const {
    meta: { title, description },
    pageTitle,
    headerLinkCopy,
    headerMotto,
    practiceAreasBody,
    businessLaw,
    employeeLaw,
    employerLaw,
    wrongfulDismissal,
    severance,
    compensation,
    approach,
  } = data.sanityHomePage;

  return (
    <MainLayout seoProps={{ title, description }}>
      <Box
        height={[350, 600]}
        bg="grey500"
        position="relative"
        sx={{
          backgroundImage:
            'url(https://storage.googleapis.com/perks-law-group/toronto-initial-frame.png)',
          backgroundSize: 'cover',
        }}
      >
        <Box
          as="video"
          alt="Toronto nightscape scenes including outside of our employment lawyer office"
          src="https://storage.googleapis.com/perks-law-group/toronto-short.mp4"
          height="100%"
          width="100%"
          sx={{ objectFit: 'cover' }}
          autoPlay
          loop
          muted
          playsInline
        />
        <Flex
          position="absolute"
          bg="rgba(0, 0, 0, 0.5)"
          color="white"
          left={0}
          top={0}
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent="center"
        >
          <Box variant="body2" fontSize="3rem">
            {formatPhoneNumber(phoneNumber)}
          </Box>
          <LinkButton to="/contact/" bVariant="primary" mt={4}>
            {headerLinkCopy}
          </LinkButton>
          <Box position="absolute" bottom={30} left={0} width="100%" height={40}>
            <InnerWrap
              pl={4}
              variant="header5Serif"
              fontWeight="500"
              borderLeft="2px solid"
              borderColor="gold"
              lineHeight="40px"
            >
              {headerMotto}
            </InnerWrap>
          </Box>
        </Flex>
      </Box>
      <OuterWrap>
        <InnerWrap>
          <Box>
            <H1>{pageTitle}</H1>
            <Box as="p" mt={5} width={['100%', 2 / 3]}>
              {practiceAreasBody}
            </Box>
          </Box>
          <Flex mx={-3} flexWrap="wrap" alignItems="stretch" justifyContent="center">
            <PracticeAreaCard
              title={businessLaw.title}
              content={businessLaw.body}
              linkCopy={businessLaw.ctaCopy}
              linkTo="/business-law/"
            />
            <PracticeAreaCard
              title={employeeLaw.title}
              content={employeeLaw.body}
              linkCopy={employeeLaw.ctaCopy}
              linkTo="/employment-law/employees/"
            />
            <PracticeAreaCard
              title={employerLaw.title}
              content={employerLaw.body}
              linkCopy={employerLaw.ctaCopy}
              linkTo="/employment-law/employers/"
            />
          </Flex>
        </InnerWrap>
      </OuterWrap>
      <OuterWrap sx={{ backgroundImage: `url(${dots})`, backgroundSize: '150px' }}>
        <Grid
          gridTemplateColumns={['none', '1fr 1fr 1fr']}
          gridTemplateRows={['none', 'auto auto']}
          gridAutoRows={['auto', 'none']}
          gridTemplateAreas={['none', `"a a b" "c d d"`]}
          gridRowGap={[5, 9]}
          alignItems="center"
          justifyItems="center"
          maxWidth={contentMaxWidth}
          width="100%"
          mx="auto"
        >
          <HandshakeIcon gridArea={['unset', 'b']} color="darkGold" fontSize={240} />
          <Box gridArea={['unset', 'a']}>
            <Box as="h2" variant="header2Serif" textAlign="center">
              {wrongfulDismissal.title}
            </Box>
            <Box as="h3" variant="italic" mt={4} color="darkGold">
              {wrongfulDismissal.subtitle}
            </Box>
            <Box mt={2}>
              <PortableText blocks={wrongfulDismissal._rawBody} />
            </Box>
          </Box>
          <DocumentIcon gridArea={['unset', 'c']} color="darkGold" fontSize={200} />
          <Box gridArea={['unset', 'd']}>
            <Box as="h2" variant="header2Serif" textAlign="center">
              {severance.title}
            </Box>
            <Box as="h3" variant="italic" mt={4} color="darkGold">
              {severance.subtitle}
            </Box>
            <Box mt={2}>
              <PortableText blocks={severance._rawBody} />
            </Box>
          </Box>
        </Grid>
      </OuterWrap>
      <OuterWrap
        position="relative"
        sx={{
          backgroundImage:
            'url(https://images.pexels.com/photos/4831/hands-coffee-smartphone-technology.jpg)',
          backgroundColor: 'black30',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <OverlayBox bg="rgba(0, 0, 0, 0.75)" />
        <InnerWrap maxWidth={550} textAlign="center" position="relative" zIndex={1} color="white">
          <Box as="h2" variant="header2Serif">
            {compensation.title}
          </Box>
          <Box as="p" mt={4} variant="body1">
            {compensation.body}
          </Box>
          <LinkButton to="/contact/" mt={6}>
            {compensation.ctaCopy}
          </LinkButton>
        </InnerWrap>
      </OuterWrap>
      <Grid gridTemplateColumns={['1fr']} bg="grey100">
        <InnerWrap maxWidth={800}>
          <Box py={[8, 9]} pl={[5, 8]} pr={5}>
            <Box as="h2" variant="header1Serif">
              {approach.title}
            </Box>
            <Box as="p" mt={5} variant="body1">
              {approach.body}
            </Box>
            <LinkButton to="/about/" bVariant="secondary" mt={5}>
              {approach.ctaCopy}
            </LinkButton>
          </Box>
        </InnerWrap>
      </Grid>
      <LatestResources />
      <Certifications bg="grey100" />
      <ContactInfoSection />
    </MainLayout>
  );
};

export default HomePage;
